/* eslint-disable no-undef */

/**
 * @function errorMessage
 * @description this is helper functions that will display a Swal window to user and save the error lo the localStorage of the browser.
 * @param {String} msg the message to show in the Swal
 * @param {Error | null | undefined} err an error parameter to save into localstorage
 * @param {Record<string, any>} options an optional parameter that allow you to customize the title and the confirm text of the Swal.
 */
const errorMessage = (
  msg = '',
  err = null,
  { title, confirmButtonText } = {
    title: '¡Error!',
    confirmButtonText: 'Cerrar',
  }
) => {
  const errs = JSON.parse(localStorage.getItem('errors') || '[]');
  if (err) {
    errs.push(btoa(err.message));
  } else {
    errs.push(btoa(msg));
  }
  localStorage.setItem('errors', JSON.stringify(errs));
  // @ts-ignore
  Swal.fire({
    title,
    text: `${msg}`,
    type: 'error',
    confirmButtonText,
  });
};

export default errorMessage;
