/* eslint-disable no-undef */
/* eslint-disable eqeqeq */
import format from 'date-fns/format';
import addMonths from 'date-fns/addMonths';
import errorMessage from './errorMessage';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import './argon';
import '../scss/argon.scss';

const location = window.location.pathname;

$(document).ready(() => {
  if (document.querySelector('.rejectionLetter')) {
    import('./rejectionLetter.js')
      .then(({ default: rejectionLetter }) => {
        rejectionLetter();
      })
      .catch((err) => console.log(err));
  }

  if (document.querySelector('.addComment')) {
    import('./ClaimFileComment.js')
      .then(({ default: claimView }) => {
        claimView();
      })
      .catch((err) => console.log(err));
  }

  if (document.querySelector('.addConditioned')) {
    import('./conditioned.js')
      .then(({ default: conditioned }) => {
        conditioned();
      })
      .catch((err) => console.log(err));
  }

  if (document.querySelector('.downloadConditioned')) {
    import('./downloadFile')
      .then(({ downloadConditioned }) => {
        downloadConditioned();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  if (document.querySelector('.downloadAnnexe')) {
    import('./downloadFile')
      .then(({ downloadAnnexe }) => {
        downloadAnnexe();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  if (document.querySelector('#expenseRatio')) {
    import('./expenseRatio.js')
      .then(({ default: expenseRatio }) => {
        expenseRatio();
      })
      .catch((err) => console.log(err));
  }

  if (document.querySelector('#addClause')) {
    import('./clausesCreator.js')
      .then(({ default: clausesCreator }) => {
        clausesCreator();
      })
      .catch((err) => console.log(err));
  }

  if (document.querySelector('.getConditioned')) {
    import('./clausesUpdater.js')
      .then(({ default: clausesUpdater }) => {
        clausesUpdater();
      })
      .catch((err) => console.log(err));
  }

  if (document.querySelector('.getAnnexe')) {
    import('./annexeClausesUpdater.js')
      .then(({ default: clausesUpdater }) => {
        clausesUpdater();
      })
      .catch((err) => console.log(err));
  }

  if (document.querySelector('.claimDownloadPdf')) {
    import('./claimDownloadPdf.js')
      .then(({ default: claimDownloadPdf }) => {
        claimDownloadPdf();
      })
      .catch((err) => console.log(err));
  }

  if (document.querySelector('.filesDownloadPdf')) {
    import('./claimDownloadPdf.js')
      .then(({ default: claimDownloadPdf }) => {
        claimDownloadPdf();
      })
      .catch((err) => console.log(err));
  }

  if (document.querySelector('.rejectedDownloadPdf')) {
    import('./claimDownloadPdf.js')
      .then(({ default: claimDownloadPdf }) => {
        claimDownloadPdf();
      })
      .catch((err) => console.log(err));
  }

  if (document.querySelector('#requestClient')) {
    import('./requestClient')
      .then(({ default: requestClient }) => {
        requestClient();
      })
      .catch((err) => console.log(err));
  }

  if (document.querySelector('#requestVehicle')) {
    import('./requestVehicle')
      .then(({ default: requestVehicle }) => {
        requestVehicle();
      })
      .catch((err) => console.log(err));
  }

  if (document.querySelector('#reserve')) {
    import('./changeReserve')
      .then(({ default: changeReserve }) => {
        changeReserve();
      })
      .catch((err) => console.log(err));
  }

  if (document.querySelector('#requestModal')) {
    import('./request')
      .then(({ default: request }) => {
        request();
      })
      .catch((err) => console.log(err));
  }

  if (document.querySelector('#report-generator')) {
    import('./reportCreator')
      .then(({ default: reportCreator }) => {
        reportCreator();
      })
      .catch((err) => console.log(err));
  }

  if (document.querySelector('#chart-prime')) {
    import('./dashboardCharts')
      .then(({ default: dashboardCharts }) => {
        dashboardCharts();
      })
      .catch((err) => console.log(err));
  }

  if (document.querySelector('#bill')) {
    import('./coverageCreator')
      .then(({ default: coverageCreator }) => {
        coverageCreator();
      })
      .catch((err) => console.log(err));
  }

  if (document.querySelector('#package')) {
    import('./packageCreator')
      .then(({ default: packageCreator }) => {
        packageCreator();
      })
      .catch((err) => console.log(err));
  }

  if (document.querySelector('#humans')) {
    import('./humanCreator')
      .then(({ default: humanCreator }) => {
        humanCreator();
      })
      .catch((err) => console.log(err));
  }

  if (document.querySelector('#isReinsuranceCheckBox')) {
    import('./reinsuredCreator')
      .then(({ default: reinsuredCreator }) => {
        reinsuredCreator();
      })
      .catch((err) => console.log(err));
  }

  if (document.querySelector('#beneficiariesTableSection')) {
    import('./beneficiaryCreator')
      .then(({ default: beneficiaryCreator }) => {
        beneficiaryCreator();
      })
      .catch((err) => console.log(err));
  }

  if (document.querySelector('#agents')) {
    import('./agentsCreator')
      .then(({ default: agentsCreator }) => {
        agentsCreator();
      })
      .catch((err) => console.log(err));
  }
  if (document.querySelector('#payrolls')) {
    import('./payrollCreator')
      .then(({ default: payrollCreator }) => {
        payrollCreator();
      })
      .catch((err) => console.log(err));
  }

  if (
    document.querySelector('#vehiclesTable') &&
    !document.querySelector('#search-vehicle')
  ) {
    import('./vehicleCreator')
      .then(({ default: vehicleCreator }) => {
        vehicleCreator();
      })
      .catch((err) => console.log(err));
  }

  if (document.querySelector('.subline-view-handler')) {
    import('./sublineCreator')
      .then(({ default: sublineCreator }) => {
        sublineCreator();
      })
      .catch((err) => console.log(err));
  }

  if (document.querySelector('.dataTable-handler')) {
    import('./dataTableCreator')
      .then(({ default: dataTableCreator }) => {
        dataTableCreator();
      })
      .catch((err) => console.log(err));
  }

  // Import the inma handler where need it!!
  if (document.getElementById('vehicle-brand')) {
    import('./inmaHandler')
      .then(({ default: inmaHandler }) => {
        inmaHandler();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // Import the dropify helper where need it!!
  if (document.querySelector('.filesHandle')) {
    import('./uploadFile')
      .then(({ default: uploadFile }) => {
        uploadFile();
      })
      .catch((err) => {
        console.log('el error es');
        console.log(err);
        errorMessage('Ha ocurrido un Error para la carga de archivo', err);
      });
  }
  if (document.querySelector('.filesCreateClientHandle')) {
    import('./uploadCreateClientFile')
      .then(({ default: uploadFile }) => {
        uploadFile();
      })
      .catch((err) => {
        errorMessage('Ha ocurrido un Error para la carga de archivo', err);
      });
  }

  // Import the dropify helper where need it!!
  if (document.querySelector('.contragaranteFiles')) {
    import('./uploadContragaranteFile')
      .then(({ default: uploadFile }) => {
        uploadFile();
      })
      .catch((err) => {
        errorMessage('Ha ocurrido un Error para la carga de archivo', err);
      });
  }

  if (document.querySelector('.policyFiles')) {
    import('./uploadPolicyFile')
      .then(({ default: uploadFile }) => {
        uploadFile();
      })
      .catch((err) => {
        errorMessage('Ha ocurrido un Error para la carga de archivo', err);
      });
  }

  if (document.querySelector('.claim-form')) {
    import('./claimCreator')
      .then(({ default: claimCreator }) => {
        claimCreator();
      })
      .catch((err) => {
        errorMessage('Ha ocurrido un Error para la carga de archivo', err);
      });
  }

  if (document.querySelector('.workshop-form')) {
    import('./workshopCreator')
      .then(({ default: workshopCreator }) => {
        workshopCreator();
      })
      .catch((err) => {
        errorMessage('Ha ocurrido un Error para la carga de archivo', err);
      });
  }

  if (document.querySelector('.billFiles')) {
    import('./uploadBillFiles')
      .then(({ default: uploadFile }) => {
        uploadFile();
      })
      .catch((err) => {
        console.log('El error es');
        console.log(err);
        errorMessage('Ha ocurrido un Error para la carga de archivo', err);
      });
  }

  if (document.querySelector('#pdf-frame')) {
    import('./documentTemplateCreator')
      .then(({ default: templateBuilder }) => {
        templateBuilder();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  if (
    location.includes('/createHealthRates') ||
    location.includes('/createSubline') ||
    location.includes('/createDataTable') ||
    location.split('/')[1].includes('dataTable') ||
    location.split('/')[1].includes('healthRate') ||
    location.split('/')[1].includes('subline')
  ) {
    import('./slider')
      .then(({ default: sliders }) => {
        sliders();
      })
      .catch((err) => console.log(err));
  }

  if (
    location.includes('/createVehicleClass') &&
    !document.querySelector('#search-plate')
  ) {
    import('./vehicleClassCreator')
      .then((vehicleClassCreator) => {
        vehicleClassCreator.default();
      })
      .catch((err) => console.log(err));
  }

  if (location.includes('claim')) {
    import('./downloadFile')
      .then(({ downloadClaim, downloadClaimFiles, downloadClaimRejected }) => {
        downloadClaim();
        downloadClaimFiles();
        downloadClaimRejected();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  if (location.includes('policy')) {
    import('./downloadFile')
      .then(({ downloadBill }) => {
        downloadBill();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  if (
    location.includes('billVehicle') ||
    location.includes('billHuman') ||
    location.includes('billBail')
  ) {
    import('./downloadFile')
      .then(({ downloadBill, downloadReinsurance }) => {
        downloadBill();
        downloadReinsurance();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  if (location.includes('revenueRatio')) {
    import('./downloadFile')
      .then(({ downloadRevenueRatio }) => {
        downloadRevenueRatio();
      })
      .catch((err) => {
        console.log(err);
      });
  }
  if (location.includes('obligation')) {
    import('./downloadFile')
      .then(({ downloadObligation }) => {
        downloadObligation();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  if (/createRevenueRatio/g.test(location)) {
    import('./revenueRatioCreator')
      .then(({ default: revenueRatioCreator }) => {
        revenueRatioCreator();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  if (location.includes('createInvoice')) {
    import('./invoiceCreator')
      .then(({ default: invoiceCreator }) => {
        invoiceCreator();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  if (location.includes('invoice')) {
    import('./downloadFile')
      .then(({ downloadInvoice }) => {
        downloadInvoice();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  if (location.includes('createCommissionRatio')) {
    import('./commissionRatioCreator')
      .then(({ default: getRevenueRatiosPerAgent }) => {
        getRevenueRatiosPerAgent();
      })
      .catch((err) => console.log(err));
  }

  if (/commissionRatio/g.test(location)) {
    import('./downloadFile')
      .then(({ downloadCommissionRatio }) => {
        downloadCommissionRatio();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  if (/notaryExpense/g.test(location)) {
    import('./downloadFile')
      .then(({ downloadNotaryExpense }) => {
        downloadNotaryExpense();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  if (/createSupplierPayment/g.test(location)) {
    import('./supplierPaymentCreator')
      .then(({ default: supplierPaymentCreator }) => {
        supplierPaymentCreator();
      })
      .catch((err) => console.log(err));
  }

  if (document.getElementById('notaryexpenses-office')) {
    import('./notaryExpensesCreator')
      .then(({ default: notaryExpensesCreator }) => {
        notaryExpensesCreator();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  if (document.getElementById('downloadSupplierPayment')) {
    import('./downloadFile')
      .then(({ downloadSupplierPayment }) => {
        downloadSupplierPayment();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  if (location.includes('createBonusRatio')) {
    import('./bonusRatioCreator')
      .then(({ default: getRevenueRatiosPerAgent }) => {
        getRevenueRatiosPerAgent();
      })
      .catch((err) => console.log(err));
  }

  if (/bonusRatio/g.test(location)) {
    import('./downloadFile')
      .then(({ downloadBonusRatio }) => {
        downloadBonusRatio();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  if (/agent-RevenueRatios/g.test(location)) {
    import('./downloadFile')
      .then(({ downloadAgentRevenueRatio }) => {
        downloadAgentRevenueRatio();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  if (/agent-CommissionRatios/g.test(location)) {
    import('./downloadFile')
      .then(({ downloadAgentCommissionRatio }) => {
        downloadAgentCommissionRatio();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  if (/agent-BonusRatios/g.test(location)) {
    import('./downloadFile')
      .then(({ downloadAgentBonusRatio }) => {
        downloadAgentBonusRatio();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  if (/\/request\/\d*\/createVehicle/g.test(location)) {
    import('./searchVehicle')
      .then(({ default: searchVehicle }) => {
        searchVehicle();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  if (document.querySelector('#downloadRequest')) {
    import('./downloadFile')
      .then(({ downloadRequest }) => {
        downloadRequest();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  if (document.querySelector('#downloadAssignmentOfReinsurance')) {
    import('./downloadFile')
      .then(({ downloadAssignmentOfReinsurance }) => {
        downloadAssignmentOfReinsurance();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  if (document.querySelector('#downloadReinsurerPayment')) {
    import('./downloadFile')
      .then(({ downloadReinsurerPayment }) => {
        downloadReinsurerPayment();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  if (document.querySelector('#downloadTaxRatio')) {
    import('./downloadFile')
      .then(({ downloadTaxRatio }) => downloadTaxRatio())
      .catch((err) => console.log(err));
  }

  if (document.querySelector('.changePolicy')) {
    import('./changePolicy')
      .then(({ default: changePolicy }) => {
        changePolicy();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  if (document.querySelector('.policyHistory')) {
    import('./policyHistory')
      .then(({ default: policyHistory }) => {
        policyHistory();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  if (/\/assignments-of-reinsurance\/\d*/g.test(window.location.pathname)) {
    import('./assignment').then((x) => x.default());
  }

  if (/\/reinsurer-payment\/create/g.test(window.location.pathname)) {
    import('./reinsurerPaymentCreator').then((x) => x.default());
  }

  if (/\/tax-ratios\/create/g.test(window.location.pathname)) {
    import('./taxRatioCreator').then((x) => x.default());
  }
  // Custom js
  $(document).ready(() => {
    $('.statusConfirm').submit(function (e) {
      e.preventDefault();
      return swal({
        title: '¿Está seguro de cambiar el estatus?',
        text: '¡No podrá revertir el cambio realizado!',
        type: 'warning',
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonClass: 'btn btn-success',
        confirmButtonText: 'Confirmar',
        cancelButtonClass: 'btn btn-danger',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.value) {
          this.submit();
          return 1;
        }
        return 0;
      });
    });

    if (document.querySelector('.formatDni')) {
      for (
        let i = 0;
        i < document.getElementsByClassName('formatDni').length;
        i++
      ) {
        document
          .getElementsByClassName('formatDni')
          .item(i)
          .addEventListener('keypress', (evt) => {
            if (
              !(
                (evt.which >= 48 && evt.which <= 57) ||
                evt.which === 8 ||
                evt.keyCode === 37 ||
                evt.keyCode === 39
              )
            ) {
              evt.preventDefault();
            }
          });

        document
          .getElementsByClassName('formatDni')
          .item(i)
          .addEventListener('paste', (evt) => {
            if (
              !(
                (evt.which >= 48 && evt.which <= 57) ||
                evt.which === 8 ||
                evt.keyCode === 37 ||
                evt.keyCode === 39
              )
            ) {
              evt.preventDefault();
            }
          });
      }
    }
    if (document.querySelector('.formatRif')) {
      for (
        let i = 0;
        i < document.getElementsByClassName('formatRif').length;
        i++
      ) {
        document
          .getElementsByClassName('formatRif')
          .item(i)
          .addEventListener('keypress', (evt) => {
            if (
              !(
                (evt.which >= 48 && evt.which <= 57) ||
                evt.which === 8 ||
                evt.keyCode === 37 ||
                evt.keyCode === 39
              )
            ) {
              evt.preventDefault();
            }
          });
      }
    }
    // if (document.querySelector(.validDate)){

    // }
    if (document.querySelector('.selectpicker')) {
      $('.selectpicker').selectpicker();
      $('.selectpicker').selectpicker('refresh');
    }
    // CreatePolicy
    if (document.querySelector('#input-driver')) {
      console.log('AJA');
      $('#input-driver').on('change', function (e) {
        console.log('Entro');
        const optionSelected = $('option:selected', this);
        const valueSelected = this.value;
        if (valueSelected === 'no')
          document.querySelector('#driverForm').style.display = 'flex';
        if (valueSelected === 'yes')
          document.querySelector('#driverForm').style.display = 'none';
      });
    }
    // Set values to dynamic field
    if ($('#dynamicMultiple')) {
      if (document.querySelector('.dynamicValue')) {
        const values = document.querySelector('.dynamicValue').value;
        $('#dynamicMultiple').selectpicker('val', JSON.parse(values));
      }
    }
    // Manejando estado de tablas
    if (document.querySelector('tbody')) {
      console.log('Table page');
      if (
        document.querySelector('tbody').id != 'coverageList' &&
        document.querySelector('tbody').id != 'humansList' &&
        document.querySelector('tbody').id != 'vehicleList'
      ) {
        if (document.querySelector('tbody').innerHTML === '') {
          console.log('No data in table');
          const tr = document.createElement('tr');
          const td = document.createElement('td');
          const text = document.createTextNode('No hay datos registrados');
          tr.id = 'no-data';
          tr.appendChild(td);
          td.appendChild(text);
          tr.title = 'No hay datos registrados';
          document.querySelector('tbody').appendChild(tr);
        } else {
          console.log('Data in table');
          $('#no-data').remove();
        }
      }
    }

    // createBill
    if (
      location.includes('createBill') ||
      document.querySelector('#validityTo')
    ) {
      // eslint-disable-next-line no-inner-declarations
      function changeDate() {
        document.querySelector('#payment-frequency-show').value =
          document.querySelector('#payment-frequency').value;
        let date = document.querySelector('#validityFrom').value;
        document.querySelector('#deliveryDate').value = date;
        if (date === '') return 0;
        const [day, month, year] = date.split('/');
        date = new Date(year, parseInt(month) - 1, day);
        const paymentMethod = parseInt(
          document.querySelector('#payment-frequency').value
        );
        if (paymentMethod === 1) {
          document.querySelector('#validityTo').value = `${day}/${month}/${
            parseInt(year) + 1
          }`;
        } else if (paymentMethod === 2) {
          const date_ = format(addMonths(date, 6), 'dd/MM/yyyy');
          document.querySelector('#validityTo').value = date_;
        } else if (paymentMethod === 4) {
          const date_ = format(addMonths(date, 3), 'dd/MM/yyyy');
          document.querySelector('#validityTo').value = date_;
        } else if (paymentMethod === 6) {
          const date_ = format(addMonths(date, 2), 'dd/MM/yyyy');
          document.querySelector('#validityTo').value = date_;
        } else {
          const date_ = format(addMonths(date, 1), 'dd/MM/yyyy');
          document.querySelector('#validityTo').value = date_;
        }
      }
      $('#validityFrom')
        .datepicker()
        .on('change', () => {
          changeDate();
        });
      document
        .querySelector('#payment-frequency')
        .addEventListener('change', changeDate);
    }

    const Datepicker = (function () {
      // Variables

      const $datepicker = $('.datepicker');

      // Methods

      function init($this) {
        const options = {
          disableTouchKeyboard: true,
          autoclose: true,
          format: 'dd/MM/yyyy',
        };

        $this.datepicker(options);
      }

      // Events

      if ($datepicker.length) {
        $datepicker.each(function () {
          init($(this));
        });
      }
    })();

    // Otra cosa
    (function ($) {
      $(() => {
        $('[data-toggle="sweet-alert"]').on('click', function () {
          const type = $(this).data('sweet-alert');

          switch (type) {
            case 'basic':
              Swal({
                title: "Here's a message!",
                text: 'A few words about this sweet alert ...',
                buttonsStyling: false,
                confirmButtonClass: 'btn btn-primary',
              });
              break;

            case 'info':
              Swal({
                title: 'Info',
                text: 'A few words about this sweet alert ...',
                type: 'info',
                buttonsStyling: false,
                confirmButtonClass: 'btn btn-info',
              });
              break;

            case 'success':
              Swal({
                title: 'Success',
                text: 'A few words about this sweet alert ...',
                type: 'success',
                buttonsStyling: false,
                confirmButtonClass: 'btn btn-success',
              });
              break;

            case 'warning':
              Swal({
                title: '¡Advertencia!',
                text: 'El % de partición entre los beneficiarios debe sumar 100',
                type: 'warning',
                buttonsStyling: false,
                confirmButtonClass: 'btn btn-warning',
              });
              break;

            case 'question':
              Swal({
                title: 'Are you sure?',
                text: 'A few words about this sweet alert ...',
                type: 'question',
                buttonsStyling: false,
                confirmButtonClass: 'btn btn-default',
              });
              break;

            case 'confirm':
              Swal({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                buttonsStyling: false,
                confirmButtonClass: 'btn btn-danger',
                confirmButtonText: 'Yes, delete it!',
                cancelButtonClass: 'btn btn-secondary',
              }).then((result) => {
                if (result.value) {
                  // Show confirmation
                  Swal({
                    title: 'Deleted!',
                    text: 'Your file has been deleted.',
                    type: 'success',
                    buttonsStyling: false,
                    confirmButtonClass: 'btn btn-primary',
                  });
                }
              });
              break;

            case 'image':
              Swal({
                title: 'Sweet',
                text: 'Modal with a custom image ...',
                imageUrl: '../../assets/img/ill/ill-1.svg',
                buttonsStyling: false,
                confirmButtonClass: 'btn btn-primary',
                confirmButtonText: 'Super!',
              });
              break;

            case 'timer':
              Swal({
                title: 'Auto close alert!',
                text: 'I will close in 2 seconds.',
                timer: 2000,
                showConfirmButton: false,
              });
              break;
            default:
              console.log('Swal Error');
          }
        });
      });
    })(jQuery);

    $(document).ready(() => {
      setTimeout(() => {
        if ($('.alert')) {
          $('.alert').alert('close');
        }
      }, 3500);
    });

    // Code for hide and show create client
    if (
      window.location.pathname.includes('/createClient') ||
      window.location.pathname.includes('/quoter') ||
      window.location.pathname.includes('/createContragarante')
    ) {
      const destroyCard = () => $('#buttons_card').remove();
      const showForm = (name) => $(`#${name}_form`).removeClass('d-none');
      const showFileUploader = () =>
        $('#createClientFile').removeClass('d-none');
      const handler = (name) => {
        $(`#people_${name}`).on('click', (e) => {
          e.preventDefault();
          destroyCard();
          showForm(`${name}_client`);
          showFileUploader();
        });
      };
      handler('natural');
      handler('juridic');
    }

    const previewFrame = document.querySelector('#preview-frame');

    if (previewFrame) {
      $('#documentTemplate').change((e) => {
        e.preventDefault();
        previewFrame.src = document.querySelector(
          `#A-${$('#documentTemplate').val()}`
        ).innerHTML;
      });
    }
  });
});
